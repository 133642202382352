import { fab } from '@fortawesome/free-brands-svg-icons';
import {
  faExternalLinkAlt,
  faChevronRight,
  faChevronLeft,
  faAngleUp,
  faShieldAlt,
  faPaperPlane,
  faArrowLeft,
  faArrowRight,
  faSearch,
  faAtom,
  faEnvelope,
  faQuestionCircle,
  faPlusCircle,
  faMinusCircle,
  faPhone,
  faSpinner,
  faIndustry,
  faComments,
  faMobile,
  faUser,
  faGifts,
  faAt,
  faLanguage,
  faEye,
  faEyeSlash,
  faCog,
  faHome,
  faSignOutAlt,
  faBars,
  faStore,
  faCaretUp,
  faCaretDown,
  faTags,
  faAddressBook,
  faCheck,
  faTimes,
  faEdit,
  faTruckMoving,
  faAngleLeft,
  faAngleRight,
  faCertificate,
  faMapMarkerAlt,
  faGift,
  faShoppingCart,
  faBirthdayCake,
  faCartPlus,
  faReceipt,
  faSave,
  faUsers,
  faStoreSlash,
  faRocket,
  faCalendarAlt,
  faChevronUp,
  faChevronDown,
  faCaretLeft,
  faCaretRight
} from '@fortawesome/free-solid-svg-icons';
import { faClock, faCheckCircle } from '@fortawesome/free-regular-svg-icons';

export const fontawesomeIcons = [
  fab,
  faClock,
  faExternalLinkAlt,
  faChevronRight,
  faChevronLeft,
  faAngleUp,
  faShieldAlt,
  faPaperPlane,
  faArrowLeft,
  faArrowRight,
  faSearch,
  faAtom,
  faEnvelope,
  faQuestionCircle,
  faPlusCircle,
  faMinusCircle,
  faPhone,
  faIndustry,
  faComments,
  faMobile,
  faUser,
  faAt,
  faLanguage,
  faEye,
  faEyeSlash,
  faCog,
  faHome,
  faSignOutAlt,
  faBars,
  faStore,
  faCaretUp,
  faCaretDown,
  faTags,
  faAddressBook,

  faAngleLeft,
  faAngleRight,
  faCertificate,
  faMapMarkerAlt,
  faGift,
  faShoppingCart,
  faCheck,
  faCheckCircle,
  faTimes,
  faEdit,
  faTruckMoving,
  faBirthdayCake,
  faCartPlus,
  faGifts,
  faReceipt,
  faSave,
  faUsers,
  faSpinner,
  faStoreSlash,
  faRocket,

  faCalendarAlt,

  faChevronUp,
  faChevronDown,
  faCaretLeft,
  faCaretRight
];
