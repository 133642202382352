import React, { useState, useEffect, useCallback  } from 'react';
import {
  Container,
  Row,
  Col
} from "reactstrap";
import { motion } from "framer-motion";
import titleImpact from "../../images/titulos/impacto_mobile.svg"

const Nums = [
  {
    title: 24,
    text:'artistas involucrados'
  },
  {
    title: 3,
    text:'comunidades apoyadas'
  },
  {
    title: 10,
    text:'donadores conscientes'
  },
  {
    title: 12,
    text:'talleres\n organizados'
  },
];
  

const CarouselImpact = () => {
  
  const [indexPage, setIsIndexPage] = useState(0);
  const totalPages = Nums.length;

  const incPag = useCallback(() => {
    const nextIndex = indexPage + 1;
    if (nextIndex < totalPages) {
      setIsIndexPage(nextIndex);
    } else {
      setIsIndexPage(0);
    }
  }, [indexPage, totalPages]);

  useEffect(() => {
    const timer = setTimeout(() => {
      incPag();
    }, 2500);
    return () => clearTimeout(timer);
  }, [indexPage, incPag]);


  return (
    <Container className="" fluid>
      <Row className="">
        <Col sm={12}>
          <h1 className="font-weight-light title_size color_tit_imp px-4">
            <img src={titleImpact} alt="Impacto" className="img-fluid title_size_impact_svg"/>
          </h1>
        </Col>

          <Col className="py-5" sm={12}>
            <motion.div
              key={indexPage}
              initial={{ x: 10, opacity: 0.5 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -10, opacity: 0.5 }}>
              <h1 className="numeral-font numeral_size text-left font-weight-light title_size yellow_text text_mod_up px-4 pb-3">
                {Nums[indexPage].title}
              </h1>
              <h2 className="secondary-font text-left secondary-font text-light font-weight-light text_mod_up px-4 pt-3 mr-5">
                {Nums[indexPage].text}
              </h2>
            </motion.div>
          </Col>

          <Col sm={12}>
            <h5 className="text-left primary-font text-light font-weight-light px-4">
                Todas nuestras acciones cuentan.

                <a 
                href="https://drive.google.com/file/d/1Alu2gC061M18QCiGH5PYMmJZ-Zi2gh3P/view?usp=sharing"
                target="_blank"
                rel="noopener noreferrer">
                  <h5 className="text-light font-weight-light">
                    Ver más sobre nuestra trayectoria.
                  </h5>
                </a>

              </h5>

          </Col>

      </Row>
    </Container>
  );
};

export default CarouselImpact;