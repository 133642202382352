import React from 'react';
import { useAppContext } from '../../context';

import icon from '../../images/miscicon.png';
const ButtonSection = () => {
  const { section } = useAppContext();

  return (
    <>
      <div
        className="d-none d-md-flex"
        style={{
          position: 'fixed',
          left: '3.5vw',
          bottom: '5vh',
          zIndex: '1000'
        }}>
        <p className="d-flex justify-content-center flex-row" href="/">
          <img src={icon} alt="btn" />
          &nbsp;
          <h5 className="primary-font main_title my-auto">{section || ''}</h5>
        </p>
      </div>
    </>
  );
};

export default ButtonSection;
