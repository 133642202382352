import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardImg
} from "reactstrap";

import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../style/carousel.css";

import trueque01 from "../../images/proyectos/trueque/trueque01.jpg";
import trueque02 from "../../images/proyectos/trueque/trueque02.jpg";

const Cards = [
  {media: trueque01},
  {media: trueque02}
];

const CarouselTrueque= () => {
  const [indexPage, setIsIndexPage] = useState(0);
  const totalPages = Cards.length;
  const decPag = () => {
    const prevIndex = indexPage - 1;
    if (prevIndex < 0){
      setIsIndexPage(totalPages-1);
    }
    if (prevIndex >= 0) {
      setIsIndexPage(prevIndex);
    }
  };

  const incPag = () => {
    const nextIndex = indexPage + 1;
    
    if (nextIndex >= totalPages){
      setIsIndexPage(0);
    }
    if (nextIndex < totalPages) {
      setIsIndexPage(nextIndex);
    }
  };

  return (
    <Container className="">
      <Row className="">
        <Col className="ml-lg-3" lg={12}>
        <motion.div
            key={indexPage}
            initial={{ x: 200, opacity: 0.5 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -200, opacity: 0.5 }}>
            
            <Card className="d-flex justify-content-center align-items-center">
              <CardImg src={Cards[indexPage].media} className="size_gallery"/>
            </Card>
          </motion.div>
        </Col>
      </Row>

      <Row className="pt-2 d-flex justify-content-center align-items-center">
        <Col xs={1} lg={4} ></Col>
        <Col xs={1} className="d-flex justify-content-right align-items-center">
          <div onClick={decPag} className="pink_text">
            <FontAwesomeIcon size="lg" icon="chevron-left" />
          </div>
        </Col>

        <Col xs={8} lg={2} className="d-flex justify-content-around flex-row">
          {Cards.map((cValue,i) => (
            <div
              key={i}
              onClick={() => setIsIndexPage(i)}
              className = {
                i === indexPage
                ? "rounded-circle itemSel"
                : "rounded-circle itemNoSel"
              }
            >
            </div>
          ))}
        </Col>
        <Col xs={1} className="d-flex justify-content-right align-items-center">
            <div onClick={incPag} className="pink_text">
              <FontAwesomeIcon size="lg" icon="chevron-right" />
            </div>
          </Col>
        <Col xs={1} lg={4} ></Col>
      </Row>
    </Container>
  );
};

export default CarouselTrueque;