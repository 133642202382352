import React, { useRef } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useVisibleSection } from '../../../hooks';

import brochazo from '../../../images/purpose/brochazo.png';
import mano from '../../../images/purpose/mano.png';
import muralistas from '../../../images/purpose/muralistas.png';
import '../../../style/ourPurpose.css';
import titlePurpose from '../../../images/titulos/proposito.svg';

function OurPurpose({ name }) {
  const purposeRef = useRef();
  useVisibleSection(purposeRef, name);
  return (
    <section ref={purposeRef}>
      <Container className="bg_main d-sm-none bg-muralistas" fluid />
      <Container
        className="bg_main d-flex flex-column justify-content-center vh-sm-100"
        fluid>
        <Row className="mt-sm-5 pt-4">
          <Col
            xs={{ size: 12, order: 2 }}
            md={{ size: 6, order: 1 }}
            className="p-0">
            <Row className="d-none d-lg-flex flex-row">
              <Col sm={6} className="p-0 container_size_hand ">
                <img
                  src={mano}
                  alt="mano"
                  className="img-fluid hand_mobile img_size_hand"
                />
              </Col>
              <Col sm={6} className="container_size_paintbrush">
                <img
                  src={brochazo}
                  alt="brochazo"
                  className="img-fluid hand_mobile img_size_paintbrush"
                />
              </Col>
            </Row>

            <Col sm={12} className="secondary_text pt-3 pt-md-0">
              <h1 className="font-weight-bold secondary-font text-left px-4 ml-lg-5 pl-lg-5">
                Llevamos arte a todas partes
                <br />
              </h1>
              <h5 className="primary-font text-left mt-md-4 px-4 ml-lg-5 pl-lg-5">
                Tenemos como objetivo hacer accesible el arte para todo público.{' '}
                La Miscelánea es una entidad que provoca un diálogo creativo
                entre el artista y la comunidad. 
                Logra un intercambio de ideas en los niños, jóvenes y adultos;
                quienes aprenden, piensan, sienten y reflexionan algo desde
                puntos de vista diferentes.
              </h5>
            </Col>
          </Col>

          <Col
            xs={{ size: 12, order: 1 }}
            sm={{ size: 6, order: 2 }}
            className="p-0 my-md-auto">
            <Col className="index_1">
              <h1 className="px-4 text-left">
                <img
                  src={titlePurpose}
                  alt="Nuestro propósito"
                  className="img-fluid title_size_svg"
                />
              </h1>
            </Col>

            <Col className="p-0 container_size_muralist d-none d-md-flex justify-content-end flex-row">
              <img
                src={muralistas}
                alt="muralistas"
                className="hand_mobile img-fluid img_size_muralist"
              />
            </Col>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
export default OurPurpose;
