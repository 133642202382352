import React, { useRef } from 'react';
import { useVisibleSection } from '../../../hooks';
import { Container, Row, Col } from 'reactstrap';

import convocatoria from '../../../images/exhibitions/convocatorias.png';
import proyect from '../../../images/exhibitions/proyectos.png';
import '../../../style/exhibitions.css';
import titleExhibitions from '../../../images/titulos/exhibiciones.svg';

function Exhibitions({ name }) {
    const exhibitRef = useRef();
    useVisibleSection(exhibitRef, name);
    return (
    <>
        <section ref={exhibitRef}>
        <Container
          className="bg_main d-flex flex-column justify-content-center pt-5"
          fluid>
        <Row className="pt-2 pt-sm-0">
            <Col xs={12} md={6} className=""></Col>
            <Col xs={12} md={6} className="">
                <h1 className="px-4 text-left">
                <img
                  src={titleExhibitions}
                  alt="Exhibiciones"
                  className="img-fluid title_size_svg"
                />
                </h1>
            </Col>
        </Row>

        <Row className="">
            <Col xs={12} lg={4} className="p-0 my-4">
                <a href="/proyectos" className="d-none d-lg-flex">
                <img
                  src={proyect}
                  alt="ojo"
                  className="dissapear_land exhibits_margin img-fluid img_size_gallery"
                />
                <div className="dissapear_land overlay_exhibits">
                    <div className="text_exhibits">
                    <h1 className="secondary-font font-weight-bold">
                        Proyectos
                    </h1>
                    <h5 className="secondary-font">
                        Una galería sobre los proyectos que se han realizado en conjunto 
                        con la comunidad artística.
                    </h5>
                    </div>
                </div>
            </a>

            <a href="/proyectos" className="d-flex d-lg-none">
                <div className="bg-byhandmobile">
                    <div className="overlay_exhibitsMob">
                    <div className="text_exhibits">
                        <h5 className="secondary-font">Proyectos</h5>
                    </div>
                    </div>
                </div>
            </a>
            <div className="d-flex d-lg-none py-2  px-3">
                <h5 className="primary-font secondary_text px-4 text-left">
                    Una galería sobre los proyectos que se han realizado en conjunto 
                    con la comunidad artística.
                </h5>
            </div>
            </Col>

            <Col
              xs={12}
              lg={4}
              className="d-none d-lg-flex justify-content-end flex-column pb-5">
                <h1 className="mb-4 text-left secondary-font font-weight-bold secondary_text px-3">
                Pasadas y futuras
                </h1>
                <h5 className="text-left primary-font px-3 secondary_text">
                    Aquí, un vistazo de los proyectos realizados y 
                    aquellos que están en puerta.
                </h5>
            </Col>

            <Col xs={12} lg={4} className="p-0 my-4">
                <a href="https://www.facebook.com/fundacionmiscelanea/events" 
                className="d-none d-lg-flex"
                target="_blank"
                rel="noopener noreferrer">
                <img
                  src={convocatoria}
                  alt="ojo"
                  className="dissapear_land exhibits_margin img-fluid img_size_gallery"
                />
                <div className="dissapear_land overlay_exhibits">
                    <div className="text_exhibits">
                        <h1 className="secondary-font font-weight-bold">
                            Convocatorias
                        </h1>
                        <h5 className="secondary-font">
                            Encuentra aquí los carteles sobre las próximas convocatorias.
                        </h5>
                    </div>
                </div>
            </a>

            <a href="https://www.facebook.com/fundacionmiscelanea/events" 
            className="d-flex d-lg-none"
            target="_blank"
            rel="noopener noreferrer">
                <div className="bg-ourexhibitmobile bg_main">
                    <div className="overlay_exhibitsMob">
                        <div className="text_exhibits">
                            <h5 className="secondary-font font-weight-bold">
                                Convocatorias
                            </h5>
                        </div>
                    </div>
                </div>
            </a>
            <div className="d-flex d-lg-none py-2 px-3">
                <h5 className="primary-font secondary_text px-4 text-left">
                    Encuentra aquí los carteles sobre las próximas convocatorias.
                </h5>
            </div>
            </Col>
        </Row>
    </Container>
    </section>
    </>
);
}
export default Exhibitions;
