import React, { Fragment } from "react"
import {
  Container,
  Row,
  Col

} from "reactstrap"
import "../../style/footer.css"
import iconFB from "../../images/footer/icono_face.svg"
import iconIG from "../../images/footer/icono_insta.svg"
import iconYT from "../../images/footer/icono_youtube.svg"
import iconTW from "../../images/footer/icono_twitter.svg"


function Footer() {

  return (
    <>
      <Container className="bg-footer d-flex justify-content-end justify-content-sm-between flex-column" fluid>
      <Row className="pt-4 my-sm-auto">
        <Col xs={12} sm={8}></Col>

        <Col xs={12} sm={4} className="d-flex justify-content-around flex-row pb-4 pb-sm-0">
          <a 
          href="https://www.facebook.com/fundacionmiscelanea" 
          target="_blank"
          rel="noopener noreferrer">
            <img
              src={iconFB}
              alt="IconFacebook"
              className="social_media"
            />
          </a>

          <a
          href="https://www.instagram.com/fundacionmiscelanea/?hl=es"
          target="_blank"
          rel="noopener noreferrer">
            <img
              src={iconIG}
              alt="IconInstagram"
              className="social_media"
            />
          </a>

          <a 
          href="https://www.youtube.com/channel/UCCR2dpydmfdYq1ty3AtuKWA"
          target="_blank"
          rel="noopener noreferrer">
            <img
              src={iconYT}
              alt="IconYoutube"
              className="social_media"
            />
          </a>

          <a
          href="https://twitter.com/fmiscelaneamx"
          target="_blank"
          rel="noopener noreferrer">
            <img
              src={iconTW}
              alt="IconTwitter"
              className="social_media"
              target="_blank"
              rel="noopener noreferrer"
            />
          </a>
          </Col>
        </Row>
        <Row className="pb-2">
          <Col className="text-center">
            <a
              href="https://www.n12.mx/"
              className="n12_text text-decoration-none text-center"
              target="_blank"
              rel="noopener noreferrer"
            >
              ©N12 Estudio, Web Solutions.
            </a>
          </Col>
        </Row>
      </Container>
    </>
  )
}
export default Footer
