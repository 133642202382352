import React, { useRef } from "react"
import { useVisibleSection } from '../../../hooks';
import { Container, Row, Col, Button } from "reactstrap"
import { HashLink } from 'react-router-hash-link';

import jenga from "../../../images/comunity/jenga.png"
import jengaMob from "../../../images/comunity/jengaMob.png"
import ojo from "../../../images/comunity/ojo.png"
import "../../../style/comunity.css"
import titleComunity from "../../../images/titulos/comunidad.svg"

function Community({ name }) {
  const communityRef = useRef();
  useVisibleSection(communityRef, name);
	return (
	<>
  <section ref={communityRef}>
    <Container
    className="bg_main d-flex flex-column justify-content-center pt-5" 
    fluid>
      <Row className="">
        <Col xs={12} className="d-md-none text-center px-0 pt-3">
          <img src={jengaMob} alt="" className="img-fluid vw-100"/>
        </Col>
        <Col xs={12} className="">
          <h1 className="text-left px-4 ml-lg-5 pl-lg-5">
            <img src={titleComunity} alt="Comunidad" className="img-fluid title_size_svg"/>
          </h1>
        </Col>
      </Row>

      <Row className="">
        <Col xs={12} md={6} className="p-0 my-auto">
          <img src={jenga} alt="" className="dissapear_land img-fluid img_size_comunity"/>
        </Col>
        <Col xs={12} md={6} lg={5}className="my-auto">
          <img src={ojo} alt="ojo" className="ml-lg-5 px-4 dissapear_land img-fluid img_size_eye d-md-none d-lg-flex"/>
          <h1 className="font-weight-bold secondary-font secondary_text px-4 text-left">
            ¿Eres artista?
          </h1>
          <h5 className="primary-font secondary_text px-4 text-left mt-md-4">
            Queremos fomentar las direcciones de la expresión de artistas mexicanos, 
            comunidades e impulsores. Promovemos artistas emergentes por medio de 
            residencias, exposiciones, talleres; comunidades que trabajen con los 
            artistas e impulsores del arte en México permitiendo el uso de espacios 
            y el impulso del mismo arte.
          </h5>	

          <div className="dissapear_land px-4">
            <HashLink to="/#blog" href="/#blog">
                <Button className="text-center px-5 d-flex justify-content-center" color="primary">
                  <h5 className="secondary-font d-none d-lg-flex"><i>¡Súmate!</i></h5>
                  <h6 className="secondary-font d-none d-md-flex d-lg-none"><i>¡Súmate!</i></h6>  
                </Button>
              </HashLink>
          </div>
              
          <div className="d-md-none d-flex justify-content-center text-center px-4">
            <HashLink href="/#blog" to="/#blog">
              <Button className="text-center justify-content-center btn-mob" color="secondary">
                <h5 className="secondary-font "><i>¡Súmate!</i></h5>
              </Button>
            </HashLink>
          </div>
        </Col>
        <Col xs={0} lg={1}></Col>
      </Row>
  </Container>
</section>
    </>
  )
}
export default Community