import React from 'react';
import { BrowserRouter as Switch } from 'react-router-dom';

import {
  Landing,
  OurPurpose,
  Community,
  Exhibitions,
  ImpactUp,
  ImpactDown
} from '../layout/sections';
import { Navb, Footer, Blog } from '../layout';
import { ButtonSections } from '../../components/elements';
import { AppWrapper } from '../../context';
import { motion } from 'framer-motion';

function LandingM() {
  return (
    <AppWrapper>
      <motion.div
        animate={{ opacity: 1 }}
        transition={{ from: 0, ease: 'easeOut', duration: 2 }}>
        <ButtonSections />
        <Switch id="navb">
          <Navb name="navb" />
        </Switch>
        <div id="landing">
          <Landing name="Página Principal" />
        </div>
        <div id="purpose">
          <OurPurpose name="Propósito" />
        </div>
        <div id="community">
          <Community name="Comunidad" />
        </div>
        <div id="exhibitions">
          <Exhibitions name="Exhibiciones" />
        </div>
        <div id="impactUp">
          <ImpactUp name="Impacto" />
        </div>
        <div id="impactDown">
          <ImpactDown name="Impacto" />
        </div>
        <div id="blog">
          <Blog name="Súmate" />
        </div>
        <div id="footer">
          <Footer name="footer" />
        </div>
      </motion.div>
    </AppWrapper>
  );
}

export default LandingM;
