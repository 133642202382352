import React from 'react';
import { connect } from 'react-redux';
import { ContactForm } from '../forms';
import {
  start_post_contact,
  reset_contact
} from '../redux/actions/contact-actions';

function ContactFormCont({ start_post_contact, reset_contact, ...rest }) {
  return (
    <ContactForm
      resetContact={reset_contact}
      startPostContact={start_post_contact}
      {...rest}
    />
  );
}

const mapStateToProps = ({ contactReducer }) => ({
  ...contactReducer
});

const mapActionsToProps = {
  start_post_contact,
  reset_contact
};

export default connect(mapStateToProps, mapActionsToProps)(ContactFormCont);
