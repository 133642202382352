import React, { useRef } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { useVisibleSection } from '../../../hooks';

import { HashLink } from 'react-router-hash-link';
import hero from '../../../images/landing/mancha.svg';
import '../../../style/landing.css';

function Landing({ name }) {
  const landingRef = useRef();
  useVisibleSection(landingRef, name);
  return (
    <section ref={landingRef}>
      <Container
        className="bg-landing d-flex flex-column justify-content-center"
        fluid>
        <Row>
          <Col sm={12} md={5}>
            <h1 className="diss_big text-light font-weight-light title-size primary-font text-center">
              M I S C E L Á N E A
            </h1>
            <h5 className=" diss_big text-light font-weight-light text-right mr-5">
              F U N D A C I Ó N
            </h5>
          </Col>
          <Col sm={12} md={7} className="dissapear_land"></Col>
        </Row>
        <Row className="img_hero_size">
          <Col>
            <img src={hero} alt="fondo landing" className="img-fluid" />
          </Col>
          <Col md={12} className="button_magic_mrg">
            <HashLink to="/#blog" href="/#blog">
              <Button
                className="my-md-2 text-center px-5 d-flex justify-content-center"
                color="primary">
                <h5 className="secondary-font d-none d-lg-flex">
                  <i>¡Súmate!</i>
                </h5>
                <h6 className="secondary-font d-none d-md-flex d-lg-none">
                  <i>¡Súmate!</i>
                </h6>
              </Button>
            </HashLink>
          </Col>
        </Row>
      </Container>

      <Container className="diss_big bg_main pb-5">
        <Row className="justify-content-center text-left">
          <Col>
            <h5 className="pt-3 px-4 primary-font secondary_text">
              Mezclamos diversas expresiones, así como un conjunto de acciones
              en favor del arte por medio de tres vías específicas: exhibiciones
              de artistas mexicanos, talleres con la comunidad, especialmente
              niños y el desarrollo/ activación de espacios - nuevos públicos
              para la expresión artística.
            </h5>
          </Col>
        </Row>
        <Row className="justify-content-center py-2 ">
          <Col className="d-flex justify-content-center text-center px-4">
            <HashLink href="/#blog" to="/#blog">
              <Button
                className="text-center justify-content-center btn-mob"
                color="secondary">
                <h5 className="secondary-font ">
                  <i>¡Súmate!</i>
                </h5>
              </Button>
            </HashLink>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
export default Landing;
