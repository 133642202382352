import React from "react";
import {Container, Row, Col, Button} from "reactstrap"
import { HashLink } from 'react-router-hash-link';
import { CarouselOaxaca,CarouselTrueque,CarouselIlustracion } from './'

import  "../../style/gallery.css";

function Proyecto(){
	return(
		<>
			<Container fluid className="size-banner mt-5"/>
			<Container className="bg_main d-flex flex-column justify-content-center " fluid>
				<Row className="pt-2" >
					<Col lg={12} className="pt-4">
						<h1 className="font-weight-light title_size main_title text-left px-4 ">
							PROYECTOS
						</h1>
						<h5 className="primary-font secondary_text px-4">
							Una galería sobre los proyectos 
							que se han realizado en conjunto con 
							la comunidad artística.
						</h5>
					</Col>
				</Row>

				<Row className="pt-2" >
					<Col xs={{ size: 12, order: 2 }} lg={{ size: 6, order: 1 }} className="px-4 py-4">
						<CarouselTrueque/>
					</Col>
					<Col xs={{ size: 12, order: 1 }} lg={{ size: 6, order: 2 }} className="d-flex justify-content-end flex-column pb-5 ">
						<h1 className="font-weight-light secondary-font secondary_text d-flex text-left px-4">
							TRUEQUE
						</h1>
						<h5 className="primary-font secondary_text px-4 text-left">
							Trueque es un evento que gestiona acciones en 
							favor del arte por medio de exposiciones de 
							artistas mexicanos o residentes en México, 
							talleres con la comunidad y charlas para un 
							acercamiento con el artista. <br/>
							Trueque es un diálogo abierto en donde el intercambio es 
							de persona a persona, se da en un esquema 
							horizontal, basado en un entorno de confianza 
							para la colectividad, donde una persona plasma 
							en lienzos y objetos partes relevantes de la mente, 
							los saberes y la memoria, guiados por el artista. <br/>
							Con esto, La Miscelánea no busca ser una galería de comercialización 
							de arte, no busca el descubrimiento del artista revelación del año, La Miscelánea encuentra el intercambio de la expresión artística para beneficio de todos a quienes llegan estas acciones, a través de artistas comprometidos con el arte participativo y compartido para trabajar en colectivo.
							<br/><br/>
							Museo Regional de Cholula, <br/>
							Cholula, Puebla. México <br/>
							Julio – Diciembre, 2017.
						</h5>
					</Col>
				</Row>

				<Row className="pt-2" >
					<Col lg={6} className="d-flex justify-content-end flex-column pb-5">
						<h1 className="font-weight-light secondary-font secondary_text d-flex text-left px-4">
							OAXACA, NIÑOS HACIA UNA PRODUCCIÓN ARTÍSTICA.
						</h1>
						<h5 className="primary-font secondary_text px-4 text-left">
							Interesados en el crecimiento de la expresión artística de México y 
							el crecimiento artístico e integral de los niños mexicanos, 18 artistas 
							mexicanos, 40 personas en logística, 3 impulsores y un Instituto (INLUSA) 
							han sido impulsores de sueños y deseos de 406+1 niños oaxaqueños que 
							trabajaron junto a artistas emergentes en un taller para la producción 
							de 407 piezas infantiles de expresión artística. Con esto, se logró la 
							satisfacción de contribuir a una niñez tocada por el arte, que no solo 
							representará una inmersión al descubrimiento de su expresión artística, 
							sino se constituirá más como una base sólida para su crecimiento personal 
							en la búsqueda de las soluciones creativas y la sensibilidad emocional 
							de las cosas.
							<br/><br/>
							INLUSA, Instituto Luis Sarmiento, <br/>
							Oaxaca, Oaxaca. México <br/>
							Abril 2017.
						</h5>
					</Col>
					<Col lg={6} className="d-flex justify-content-center flex-column px-4 py-4">
						<CarouselOaxaca/>
					</Col>
				</Row>

				<Row className="py-2">
					<Col xs={{ size: 12, order: 2 }} lg={{ size: 6, order: 1 }} className="d-flex justify-content-center flex-column px-4 py-4">
							<CarouselIlustracion/>
					</Col>
					<Col xs={{ size: 12, order: 1 }} lg={{ size: 6, order: 2 }} className="d-flex justify-content-end flex-column pb-5">
						<h1 className="font-weight-light secondary-font secondary_text d-flex text-left px-4">
							ILUSTRACIÓN COLOREABLE
						</h1>
						<h5 className="primary-font secondary_text px-4 text-left">
							El arte accesible encuentra nuevos medios para auto difundirse, 
							pero se fundamenta fuertemente en su tradición cultural para 
							explorarse, crecer y dispersarse en nuevos medios por sus 
							propios méritos interactuantes. <br/>
							Arte accesible por medio de la Ilustración Coloreable 
							se fundamenta en cuatro temáticas para generar arte para 
							todos: primera, el entendimiento personal 
							y del entorno para generar arte; segunda, sobre lo que el arte 
							genera en el artista y como se proyecta; tercera, sobre el 
							cuestionamiento del arte y los nuevos medios de expresión y 
							cuarta, sobre el arte interactivo y al alcance inmediato. <br/>
							El artista produce, la comunidad lo interpreta y lo colorea, 
							la exhibición visibiliza las piezas en conjunto.
							<br/><br/>
							Corredor Casa del Caballero Águila, <br/>
							Cholula, Puebla. México <br/>
							Diciembre, 2016.
						</h5>
					</Col>
				</Row>

				<Row className="pb-5" >
					<Col lg={12} className="pt-2">
						<h5 className="primary-font secondary_text px-4 text-left">
							¿Te interesa colaborar con nosotros?
						</h5>

						<div className="dissapear_land px-4 pt-3">
							<HashLink to="/#blog" href="/#blog">
									<Button className="text-center px-5 d-flex justify-content-center" color="secondary">
										<h5 className="secondary-font d-none d-lg-flex"><i>¡Súmate!</i></h5>
										<h6 className="secondary-font d-none d-md-flex d-lg-none"><i>¡Súmate!</i></h6>  
									</Button>
								</HashLink>
						</div>
								
						<div className="d-md-none d-flex justify-content-center text-center px-4 pt-3">
							<HashLink href="/#blog" to="/#blog">
								<Button className="text-center justify-content-center btn-mob" color="secondary">
									<h5 className="secondary-font "><i>¡Súmate!</i></h5>
								</Button>
							</HashLink>
						</div>

					</Col>
				</Row>

			</Container>
		</>
	)
	}

export default Proyecto;