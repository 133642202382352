import { createContext, useContext, useState } from 'react';

const initialState = 'Página principal';
 
const AppContext = createContext(null);

export function AppWrapper({ children }) {
  const [section, setSection] = useState(initialState);

  return (
    <AppContext.Provider value={{ section, setSection }}>
      {children}
    </AppContext.Provider>
  );
}

export function useAppContext() {
  return useContext(AppContext);
}
