import React, { useRef } from "react"
import { useVisibleSection } from '../../hooks';

import { Container, Row, Col } from "reactstrap"
import contact from "../../images/blog/contact.png"
import botella from "../../images/blog/botella.png"
import "../../style/footer.css"
import {ContactFormCont} from "../../containers"

function Blog({ name }) {
  const blogRef = useRef();
  useVisibleSection(blogRef, name);
	return (
	<>
  <section ref={blogRef}>
      <Container className="bg_main d-flex justify-content-center flex-column vh-100" fluid>
        <Row className="">
            <Col xs={12} sm={6} className="p-0">
              <img src={contact} alt="sumate" className="d-none d-md-flex img-fluid img_size_sum"></img>
              <div className="px-3">
                <h1 className="font-weight-light title_size secondary_text d-flex text-left px-4 ml-md-5 pl-md-5 d-md-none">
                  SÚMATE
                </h1>
              </div>
              <img src={contact} alt="sumate" className="img-fluid d-flex d-md-none vw-100"></img>
            </Col>
            
            <Col xs={12} sm={6} className="">
              <h1 className="font-weight-light title_size secondary_text px-4 text-left  d-none d-md-flex">
                SÚMATE
              </h1>
              <div className="px-3">
                <ContactFormCont/>
              </div>
              <img src={botella} alt="botella" className="d-none d-lg-flex mx-auto img_size_bottle img-fluid"/>
              <h1 className="secondary-font secondary_text text-left text-md-right pl-4 pl-md-0 pr-md-5 pt-md-2 " >¡Escríbenos!</h1>
            </Col>
        </Row>

      </Container>
    </section>
    </>
  )
}
export default Blog
