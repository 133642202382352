import React from 'react';
import { Navb, Footer } from '../layout';
import { Proyecto } from '../elements'
import { motion } from "framer-motion"

function Proyect() {
  return (
    <>
<motion.div
  animate={{opacity:1}}
  transition={{from:0 , ease: "easeOut", duration: 2  }}>
    <Navb/>
    <Proyecto/>
    <Footer/>
    </motion.div>
    </>
  );
}

export default Proyect;