import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import {
  Container,
  NavItem,
  Navbar,
  NavbarBrand,
  Nav,
  Collapse,
  NavbarToggler  
} from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../../images/logoD.svg"
import "../../style/navbar.css"


function Navb() {

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const routeLinks = [
    {
      to: '/#purpose',
      name: 'Propósito',
      scroll: '/#purpose'
    },
    {
      to: '/#community',
      name: 'Comunidad',
      scroll: '/#community'
    },
    {
      to: '/#exhibitions',
      name: 'Exhibiciones',
      scroll: '/#exhibitions'
    },
    {
      to: '/#impact',
      name: 'Impacto',
      scroll: '/#impact'
    },
    {
      to: '/#blog',
      name: 'Súmate',
      scroll: '/#blog'
    }
  ];

  return (
      <Container className= "" fluid>
        <Navbar className="p-0 vw-100" fixed={'top'} expand={'lg'} style={{background:`#ECEAEA`}}>
          <NavbarBrand className="m-0" tag={Link} to="/">
            <img
              src={logo}
              alt=" Log Menu"
              className="ml-4 pl-2 size_logo"
            />
          </NavbarBrand>
          
            <NavbarToggler
            collapse={isOpen}
            className='navbar-toggler'
            onClick={toggle}>
              <FontAwesomeIcon
              size="2x"
              className="pink_text"
              icon='bars'
            />
            </NavbarToggler>

            <Collapse className="justify-content-center" isOpen={isOpen} navbar>
              <Nav className="d-none d-lg-flex" navbar>
                <NavItem className="">
                  <div className="secondary_text">
                    <h5 className="secondary-font ">
                    <span className="font-weight-light">Nuestro </span>
                    
                    <a href="/#purpose" className="pink_text font-weight-bold">propósito </a>{/*Convertir a Link*/}

                    <span className="font-weight-light"> es formar una </span>

                    <a href="/#community" className="pink_text font-weight-bold">comunidad</a>{/*Convertir a Link*/}

                    <span className="font-weight-light"> de </span>

                    <a href="/#exhibitions" className="pink_text font-weight-bold">artistas</a>{/*Convertir a Link*/}

                    <span className="font-weight-light"> con un </span>

                    <a href="/#impacto" className="pink_text font-weight-bold">impacto</a>{/*Convertir a Link*/}

                    <span className="font-weight-light"> positivo. </span>

                    <a href="/#blog" className="pink_text font-weight-bold">¡Súmate!</a>{/*Convertir a Link*/}

                </h5>
              </div>
            </NavItem>
              </Nav>
              <Nav className="d-flex d-lg-none text-right bg_nav py-3" navbar>
                
                {routeLinks.map(({ to, name, scroll }, i) => (
                  <NavItem className="mx-4 py-1 " key={i}>
                    <HashLink 
                      className="text-light"
                      tag={scroll ? 'a' : Link}
                      href={scroll ? scroll : to}
                      to={to}>
                      <h5>
                        {name}
                      </h5>    
                    </HashLink>
                  </NavItem>
                ))}      
              </Nav>
            </Collapse>  

          </Navbar>
      </Container>
      )
}

      
export default Navb;