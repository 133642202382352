import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  LandingM,
  Proyect
} from './components/routes';

import { fontawesomeIcons } from './config/fontawesome';

import './App.css';


library.add(...fontawesomeIcons);


function App() {
    return (
      <Router>
        <div className="App">
            <Switch>

              <Route exact path="/" component={LandingM}/>
              <Route exact path="/proyectos" component={Proyect}/>
            
            </Switch>

        </div>
      </Router>
    );
  }

export default App;