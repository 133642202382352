import { useState, useEffect } from 'react';
import { debounce } from '../utils';
import { useAppContext } from '../context';

export function useScroll() {
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [bodyOffset, setBodyOffset] = useState(
    document.body.getBoundingClientRect()
  );
  const [scrollY, setScrollY] = useState(bodyOffset.top);
  const [scrollX, setScrollX] = useState(bodyOffset.left);
  const [scrollDirection, setScrollDirection] = useState();

  const listener = (e) => {
    setBodyOffset(document.body.getBoundingClientRect());
    setScrollY(-bodyOffset.top);
    setScrollX(bodyOffset.left);
    setScrollDirection(lastScrollTop > -bodyOffset.top ? 'down' : 'up');
    setLastScrollTop(-bodyOffset.top);
  };

  useEffect(() => {
    window.addEventListener('scroll', listener);
    return () => {
      window.removeEventListener('scroll', listener);
    };
  });

  return {
    scrollY,
    scrollX,
    scrollDirection
  };
}

const DEBOUNCE_TIME = 1;

export function useVisible(theRef, defVal) {
  const [visible, setVisible] = useState(defVal ? defVal : false);
  const checkInView = debounce(() => {
    const place = theRef.current.getBoundingClientRect();
    if (place) {
      if (place.top >= 0 && place.bottom <= window.innerHeight) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    }
  }, DEBOUNCE_TIME);
  useEffect(() => {
    window.addEventListener('scroll', checkInView);
    return () => window.removeEventListener('scroll', checkInView);
    //eslint-disable-next-line
  }, []);
  return visible;
}

export function useVisibleSection(sectionRef, sectionName) {
  const visible = useVisible(sectionRef);
  const { section, setSection } = useAppContext();
  useEffect(() => {
    if (visible && section !== sectionName) {
      setSection(sectionName);
    }
  }, [visible, section, sectionName, setSection]);
}
