import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardImg
} from "reactstrap";

import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../style/carousel.css";

import ic01 from "../../images/proyectos/ilustracion/IC01.jpg";
import ic02 from "../../images/proyectos/ilustracion/IC02.jpg";
import ic03 from "../../images/proyectos/ilustracion/IC03.jpg";
import ic04 from "../../images/proyectos/ilustracion/IC04.jpg";
import ic05 from "../../images/proyectos/ilustracion/IC05.jpg";
import ic06 from "../../images/proyectos/ilustracion/IC06.jpg";
import ic07 from "../../images/proyectos/ilustracion/IC07.jpg";
import ic08 from "../../images/proyectos/ilustracion/IC08.jpg";
import ic09 from "../../images/proyectos/ilustracion/IC09.jpg";
import ic10 from "../../images/proyectos/ilustracion/IC10.jpg";
import ic11 from "../../images/proyectos/ilustracion/IC11.jpg";
import ic12 from "../../images/proyectos/ilustracion/IC12.jpg";
import ic13 from "../../images/proyectos/ilustracion/IC13.jpg";
import ic14 from "../../images/proyectos/ilustracion/IC14.jpg";
import ic15 from "../../images/proyectos/ilustracion/IC15.jpg";
import ic16 from "../../images/proyectos/ilustracion/IC16.jpg";
import ic17 from "../../images/proyectos/ilustracion/IC17.jpg";
import ic18 from "../../images/proyectos/ilustracion/IC18.jpg";
import ic19 from "../../images/proyectos/ilustracion/IC19.jpg";
import ic20 from "../../images/proyectos/ilustracion/IC20.jpg";
import ic21 from "../../images/proyectos/ilustracion/IC21.jpg";
import ic22 from "../../images/proyectos/ilustracion/IC22.jpg";
import ic23 from "../../images/proyectos/ilustracion/IC23.jpg";
import ic24 from "../../images/proyectos/ilustracion/IC24.jpg";
//import ic25 from "../../images/proyectos/ilustracion/IC25.jpg";
//import ic26 from "../../images/proyectos/ilustracion/IC26.jpg";
//import ic27 from "../../images/proyectos/ilustracion/IC27.jpg";
//import ic28 from "../../images/proyectos/ilustracion/IC28.jpg";

const Cards = [
  {
    media1: ic01,
    media2: ic02,
    media3: ic03,
    media4: ic04,
    media5: ic05,        
    media6: ic06
  },
  {
    media1: ic07,
    media2: ic08,
    media3: ic09,
    media4: ic10,
    media5: ic11,        
    media6: ic12
  },
  {
    media1: ic13,
    media2: ic14,
    media3: ic15,
    media4: ic16,
    media5: ic17,        
    media6: ic18
  },
  {
    media1: ic19,
    media2: ic20,
    media3: ic21,
    media4: ic22,
    media5: ic23,        
    media6: ic24
  }
];

const CarouselIlustracion = () => {
  const [indexPage, setIsIndexPage] = useState(0);
  const totalPages = Cards.length;
  const decPag = () => {
    const prevIndex = indexPage - 1;
    if (prevIndex < 0){
      setIsIndexPage(totalPages-1);
    }
    if (prevIndex >= 0) {
      setIsIndexPage(prevIndex);
    }
  };

  const incPag = () => {
    const nextIndex = indexPage + 1;
    
    if (nextIndex >= totalPages){
      setIsIndexPage(0);
    }
    if (nextIndex < totalPages) {
      setIsIndexPage(nextIndex);
    }
  };

  return (
    <Container className="">
      <Row className="">
        <Col xs={12}>
        <motion.div
            key={indexPage}
            initial={{ x: 200, opacity: 0.5 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -200, opacity: 0.5 }}>
            
            <Card className="d-flex justify-content-center flex-row">
              <div>
                  <CardImg src={Cards[indexPage].media1} className="pr-1"/>
                  <CardImg src={Cards[indexPage].media3} className="py-2 pr-1" />
                  <CardImg src={Cards[indexPage].media5} className="pr-1"/>
                </div>
                <div>
                  <CardImg src={Cards[indexPage].media2} className="pl-1"/>
                  <CardImg src={Cards[indexPage].media4} className="py-2 pl-1"/>
                  <CardImg src={Cards[indexPage].media6} className="pl-1"/>
                </div>
            </Card>
          </motion.div>
        </Col>
      </Row>

      <Row className="pt-2 d-flex justify-content-center align-items-center">
        <Col xs={1} lg={4} ></Col>
        <Col xs={1} className="d-flex justify-content-right align-items-center">
          <div onClick={decPag} className="pink_text">
            <FontAwesomeIcon size="lg" icon="chevron-left" />
          </div>
        </Col>

        <Col xs={8} lg={2} className="d-flex justify-content-around flex-row">
          {Cards.map((cValue,i) => (
            <div
              key={i}
              onClick={() => setIsIndexPage(i)}
              className = {
                i === indexPage
                ? "rounded-circle itemSel"
                : "rounded-circle itemNoSel"
              }
            >
            </div>
          ))}
        </Col>
        <Col xs={1} className="d-flex justify-content-right align-items-center">
          <div onClick={incPag} className="pink_text">
            <FontAwesomeIcon size="lg" icon="chevron-right" />
          </div>
        </Col>
        <Col xs={1} lg={4} ></Col>
      </Row>
    </Container>
  );
};

export default CarouselIlustracion;