import React, { useState, useEffect, Fragment } from "react"
import { Container, Row, Col, Alert, Button, Form, FormGroup, Input,InputGroup,InputGroupAddon } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { faSpinner } from "@fortawesome/free-solid-svg-icons";

export default function ContactForm({
  isFetching,
  startPostContact,
  resetContact,
  message: globalMessage,
}) {
  const [showMessage, setShowMessage] = useState(false);
  const [formVals, setFormVals] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const getValue = ({ target: { name, value } }) => {
    setFormVals({
      ...formVals,
      [name]: value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    startPostContact(formVals);
  };
  useEffect(() => {
    if (globalMessage) {
      toggleMessage();
      setFormVals({
        email: "",
        message: "",
      });
    }
  }, [globalMessage]);

  const toggleMessage = () => {
    if (showMessage) {
      resetContact();
    }
    setShowMessage(!showMessage);
  };

  const { email, message } = formVals;



  return (
     <>
      {isFetching ? (
        <Container>
          <Row className="justify-content-center text-center">
            <Col>
              <FontAwesomeIcon size="3x" spin icon={faSpinner} />
            </Col>
          </Row>
        </Container>
      ) : (
        <Row className="d-flex flex-column justify-content-center my-md-0 px-md-0 my-5 ">
          <Form onSubmit={handleSubmit} className="px-4">
            <FormGroup className="">
              <Input value={message}
                onChange={getValue}
                required
                type="textarea"
                name="message"
                id="message"
                placeholder="Escribe tu mensaje" 
                className=" mb-md-4 pt-md-4 rounded_form"/>
            </FormGroup>

          <InputGroup>
            <Input 
              value={email}
              onChange={getValue}
              required
              type="email"
              name="email"
              id="email"
              placeholder="Ingresa tu correo" 
              className="rounded_input"/>
            <InputGroupAddon addonType="append"><Button type="submit" color="secondary">Enviar</Button></InputGroupAddon>
          </InputGroup>
          </Form>
        </Row>
        )}
        {globalMessage && (
        <Alert
          isOpen={showMessage}
          toggle={toggleMessage}
          className="mt-1"
          color="primary"
          id="#alert"
        >
          The message has been sent successfully.
        </Alert>
      )}
    </>
  );
}