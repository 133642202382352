import React, { useRef } from 'react';
import { Container } from 'reactstrap';
import { useVisibleSection } from '../../../hooks';
import '../../../style/impact.css';

function ImpactUp({ name }) {
  const impactRef = useRef();
  useVisibleSection(impactRef, name);
  return (
      <section ref={impactRef}>
        <Container
          className="bg-top d-none d-lg-flex flex-column justify-content-center vh-100"
          fluid>
        </Container>
      </section>
  );
}
export default ImpactUp;
