import React from "react"
import {Container, Row, Col} from "reactstrap"

import  "../../style/gallery.css";

import ojoamarillo from "../../images/gallery/ojoamarillo.png";
import gallery1 from "../../images/gallery/gallery1.png";

function Coleccion(){
    return(
        <>
        <Container className="bg_main d-flex flex-column justify-content-center vh-100" fluid>
            <Row className="d-flex align-items-center" >
                <Col sm={12} md={4} >
                </Col>
                <Col sm={12} md={4} className="text-center" >
                    <img src={ojoamarillo} alt="ojo amarillo" className="img-fluid" />
                    <h1 className="gallery_title primary-font main_title">
                        AAAAAAAAAAAAAAAAA
                    </h1>
                    <h4 className="primary-font secondary_text px-md-5">
                        AAAAAAAAAAAAAAAAAAAAAAAAAA 
                    </h4>

                    <img src={gallery1} alt="paisaje" className="img-fluid" />
                    <div className="overlay_galleryl">
                    <div className="text_galleryl">
                        <h1 className="secondary-font font-weight-bold">
                            Paisaje
                        </h1>
                        <h5 className="secondary-font">
                            Oleo sobre lienzo 150cm x 50cm
                        </h5>
                    </div>
                </div>
                </Col>
                <Col sm={12} md={1} >
                </Col>
                <Col sm={12} md={2} className="" >
                    <h2 className="secondary-font main_title">
                        "Uei tlamatilistli,<br/>
                        uei tlamachtili,<br/>
                        Uei siatlamachtiani,<br/>
                        uei tlamachtiani."<br/>
                    </h2>
                    <h5 className="mt-5 secondary-font secondary_text">
                        "Gran sabiduría, gran enseñanza, gran maestra, 
gran maestro."
                    </h5>
                </Col>
                <Col sm={12} md={1} >
                </Col>

            </Row>
        </Container>
        </>
    )

}

export default Coleccion