import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardImg
} from "reactstrap";

import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../style/carousel.css";

import oax01 from "../../images/proyectos/oaxaca/oax01.jpg";
import oax02 from "../../images/proyectos/oaxaca/oax02.jpg";
import oax03 from "../../images/proyectos/oaxaca/oax03.jpg";
import oax04 from "../../images/proyectos/oaxaca/oax04.jpg";
import oax05 from "../../images/proyectos/oaxaca/oax05.jpg";
import oax06 from "../../images/proyectos/oaxaca/oax06.jpg";
import oax07 from "../../images/proyectos/oaxaca/oax07.jpg";
import oax08 from "../../images/proyectos/oaxaca/oax08.jpg";
import oax09 from "../../images/proyectos/oaxaca/oax09.jpg";
import oax10 from "../../images/proyectos/oaxaca/oax10.jpg";
import oax11 from "../../images/proyectos/oaxaca/oax11.jpg";
import oax12 from "../../images/proyectos/oaxaca/oax12.jpg";
import oax13 from "../../images/proyectos/oaxaca/oax13.jpg";
import oax14 from "../../images/proyectos/oaxaca/oax14.jpg";
import oax15 from "../../images/proyectos/oaxaca/oax15.jpg";
import oax16 from "../../images/proyectos/oaxaca/oax16.jpg";
import oax17 from "../../images/proyectos/oaxaca/oax17.jpg";
import oax18 from "../../images/proyectos/oaxaca/oax18.jpg";
import oax19 from "../../images/proyectos/oaxaca/oax19.jpg";
import oax20 from "../../images/proyectos/oaxaca/oax20.jpg";
import oax21 from "../../images/proyectos/oaxaca/oax21.jpg";
import oax22 from "../../images/proyectos/oaxaca/oax22.jpg";
import oax23 from "../../images/proyectos/oaxaca/oax23.jpg";
import oax24 from "../../images/proyectos/oaxaca/oax24.jpg";
import oax25 from "../../images/proyectos/oaxaca/oax25.jpg";
import oax26 from "../../images/proyectos/oaxaca/oax26.jpg";
import oax27 from "../../images/proyectos/oaxaca/oax27.jpg";
import oax28 from "../../images/proyectos/oaxaca/oax28.jpg";
import oax29 from "../../images/proyectos/oaxaca/oax29.jpg";
import oax30 from "../../images/proyectos/oaxaca/oax30.jpg";
import oax31 from "../../images/proyectos/oaxaca/oax31.jpg";
import oax32 from "../../images/proyectos/oaxaca/oax32.jpg";
import oax33 from "../../images/proyectos/oaxaca/oax33.jpg";
import oax34 from "../../images/proyectos/oaxaca/oax34.jpg";
import oax35 from "../../images/proyectos/oaxaca/oax35.jpg";
import oax36 from "../../images/proyectos/oaxaca/oax36.jpg";


const Cards = [
  {
    media1: oax01,
    media2: oax02,
    media3: oax03,
    media4: oax04,
    media5: oax05,        
    media6: oax06
  },
  {
    media1: oax07,
    media2: oax08,
    media3: oax09,
    media4: oax10,
    media5: oax11,        
    media6: oax12
  },
  {
    media1: oax13,
    media2: oax14,
    media3: oax15,
    media4: oax16,
    media5: oax17,        
    media6: oax18
  },
  
  {
    media1: oax19,
    media2: oax20,
    media3: oax21,
    media4: oax22,
    media5: oax23,        
    media6: oax24
  },
  
  {
    media1: oax25,
    media2: oax26,
    media3: oax27,
    media4: oax28,
    media5: oax29,        
    media6: oax30
  },
  
  {
    media1: oax31,
    media2: oax32,
    media3: oax33,
    media4: oax34,
    media5: oax35,        
    media6: oax36
  },
  
];

const CarouselOaxaca = () => {
  const [indexPage, setIsIndexPage] = useState(0);
  const totalPages = Cards.length;
  const decPag = () => {
    const prevIndex = indexPage - 1;
    if (prevIndex < 0){
      setIsIndexPage(totalPages-1);
    }
    if (prevIndex >= 0) {
      setIsIndexPage(prevIndex);
    }
  };

  const incPag = () => {
    const nextIndex = indexPage + 1;
    
    if (nextIndex >= totalPages){
      setIsIndexPage(0);
    }
    if (nextIndex < totalPages) {
      setIsIndexPage(nextIndex);
    }
  };

  return (
    <Container className="">
      <Row className="">
        <Col xs={12}>
        <motion.div
            key={indexPage}
            initial={{ x: 200, opacity: 0.5 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -200, opacity: 0.5 }}>
            
            <Card className="d-flex justify-content-center flex-row">
              <div>
                <CardImg src={Cards[indexPage].media1} className="pr-1"/>
                <CardImg src={Cards[indexPage].media3} className="py-2 pr-1" />
                <CardImg src={Cards[indexPage].media5} className="pr-1"/>
              </div>
              <div>
                <CardImg src={Cards[indexPage].media2} className="pl-1"/>
                <CardImg src={Cards[indexPage].media4} className="py-2 pl-1"/>
                <CardImg src={Cards[indexPage].media6} className="pl-1"/>
              </div>
            </Card>
          </motion.div>
        </Col>
      </Row>

      <Row className="pt-2 d-flex justify-content-center align-items-center">
        <Col xs={1} lg={4} ></Col>
        <Col xs={1} className="d-flex justify-content-right align-items-center">
          <div onClick={decPag} className="pink_text">
            <FontAwesomeIcon size="lg" icon="chevron-left" />
          </div>
        </Col>

        <Col xs={8} lg={2} className="d-flex justify-content-around flex-row">
          {Cards.map((cValue,i) => (
            <div
              key={i}
              onClick={() => setIsIndexPage(i)}
              className = {
                i === indexPage
                ? "rounded-circle itemSel"
                : "rounded-circle itemNoSel"
              }
            >
            </div>
          ))}
        </Col>
        <Col xs={1} className="d-flex justify-content-right align-items-center">
          <div onClick={incPag} className="pink_text">
            <FontAwesomeIcon size="lg" icon="chevron-right" />
          </div>
        </Col>
        <Col xs={1} lg={4} ></Col>
      </Row>

    </Container>
  );
};

export default CarouselOaxaca;