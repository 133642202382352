import { captureException } from "@sentry/browser";

// gets called at the end of a bunch of events
export function debounce(func, delay) {
  let later;
  return () => {
    if (later) clearTimeout(later);
    later = setTimeout(() => {
      later = null;
      // console.log('working?')
      func();
    }, delay);
  };
}

// gets called at regular intervals
export function throttle(func, delay) {
  let throttled = false;
  return () => {
    if (!throttled) {
      func();
      throttled = true;
      setTimeout(() => (throttled = false), delay);
    }
  };
}

export function rangeProportional(bought_kg, max_points) {
  return Math.ceil(bought_kg * max_points * 100) / 100;
}

export function prevRangeProportional(bought_kg, range, max_points) {
  return Math.floor((bought_kg * max_points) / range);
}

export function prevRangeCumulative(bought_kg, range, max_points) {
  return Math.ceil((bought_kg / range) * max_points);
}

// function debounce(func, wait, immediate) {
// 	var timeout;
// 	return function() {
// 		var context = this, args = arguments;
// 		var later = function() {
// 			timeout = null;
// 			if (!immediate) func.apply(context, args);
// 		};
// 		var callNow = immediate && !timeout;
// 		clearTimeout(timeout);
// 		timeout = setTimeout(later, wait);
// 		if (callNow) func.apply(context, args);
// 	};
// };

export const setHJson = (i, method, b) => {
  i = {
    method,
    mode: "cors",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `JWT ${
        sessionStorage.getItem(process.env.REACT_APP_TOKEN_NAME) || ""
      }`
    })
  };
  if (b) {
    i.body = JSON.stringify(b);
  }
  return i;
};

export function setHFData(i, method, b) {
  i = {
    method,
    mode: "cors",
    headers: new Headers({
      Accept: "application/json, */*",
      Authorization: `JWT ${sessionStorage.getItem(
        process.env.REACT_APP_TOKEN_NAME
      )}`
    }),
    body: b
  };
  return i;
}

export const fetchStatus = (response) => {
  if (!response.ok) {
    if (process.env.NODE_ENV === "production") {
      captureException(response.statusText);
    }
  }
  return response.json(); // or return response.text()
};

export const getErrors = (error) => {
  // captureException(error);
  if (process.env.NODE_ENV === "production") {
    captureException(error);
  }

  return { error };
};

// esta función quita cualquier valor vacío de un proyecto, quita cualquier llave que tenga "" como valor
export const remEmpty = (b) => {
  let n = {};
  Object.keys(b).map((a) => {
    let c = b[a];
    if (Array.isArray(c) && !c.length) {
      c = "";
    }
    if (typeof c !== "function" && typeof c === "object") {
      c = notEmpty(c) ? c : "";
    }
    if (c && c !== "" && typeof c !== "undefined") {
      return (n = {
        ...n,
        [a]: c
      });
    }
    return null;
  });
  return n;
};

export const notEmpty = (obj) => {
  let resp = false;
  for (let key in obj) {
    if (obj[key] !== "") {
      resp = true;
    }
  }
  return resp;
};

export const inputToDate = (date) => {
  // /(\d{4}-\d{2}-\d{2})/g for testing DDDD-DD-DD
  if (date === "") {
    return "";
  } else if (typeof date.getMonth === "function" || date.indexOf("T") > 0) {
    return date;
  }
  let [year, month, day] = date.split("-");
  return new Date(year, parseInt(month, 10) - 1, day);
};

export const shorten = (str, maxLen, separator = " ") => {
  if (str.length <= maxLen) return str;
  return str.substr(0, str.lastIndexOf(separator, maxLen)) + " ...";
};
