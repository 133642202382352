import React, { useRef } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { useVisibleSection } from '../../../hooks';
import tocadisco from '../../../images/impact/tocadisco.png';
import '../../../style/impact.css';
import titleImpact from '../../../images/titulos/impacto.svg';
import { CarouselImpact } from '../../elements';

function ImpactDown({ name }) {
  const impactRef = useRef();
  useVisibleSection(impactRef, name);
  return (
      <section ref={impactRef}>
        <Container
          id="impacto"
          className="bg-down d-flex flex-column justify-content-center vh-100"
          fluid>
          <Row className="d-none d-lg-flex">
            <Col xs={12} lg={5} className="my-auto">
              <img
                src={tocadisco}
                alt="tocadisco"
                className="impact_dissapear img-fluid img_size_impact px-4 ml-lg-5 pl-lg-5 py-3"
              />
              <h1 className="px-4 ml-lg-5 pl-lg-5">
                <img
                  src={titleImpact}
                  alt="Impacto"
                  className="img-fluid title_size_impact_svg"
                />
              </h1>
              <h5 className="primary-font secondary_text px-4 ml-lg-5 pl-lg-5 text-left">
                Todas nuestras acciones cuentan.

                <a 
                href="https://drive.google.com/file/d/1Alu2gC061M18QCiGH5PYMmJZ-Zi2gh3P/view?usp=sharing"
                target="_blank"
                rel="noopener noreferrer">
                  <h5>
                    Ver más sobre nuestra trayectoria.
                  </h5>
                </a>

              </h5>

              <div className="dissapear_land d-none">
                <Button
                  href="/dona"
                  className="ml-md-5 my-md-5"
                  color="primary">
                  <h5 className="secondary-font">
                    <i>Dona</i>
                  </h5>
                </Button>
              </div>

              <div className=" justify-content-center text-center d-none">
                <Button href="/dona" className="mb-5" color="primary">
                  <h5 className="secondary-font">
                    <i>Dona</i>
                  </h5>
                </Button>
              </div>
            </Col>

            <Col xs={12} lg={1} className="mb-4"></Col>

            <Col xs={12} lg={2} className="ml-4">
              <div className="numeral_down">
                <h1 className="numeral-font numeral_size text-md-left text-center font-weight-light  yellow_text text_mod_down ">
                  24
                </h1>
                <h2 className="secondary-font text-md-left text-center text-light font-weight-light ">
                  artistas involucrados
                </h2>
              </div>

              <div className="d-flex flex-column custom_margin_impact">
                <h1 className="numeral-font numeral_size text-md-left text-center font-weight-light title_size yellow_text numeral2_marg">
                  3
                </h1>
                <h2 className="text-md-left text-center  secondary-font text-light font-weight-light numeral2_marg">
                  comunidades apoyadas
                </h2>
                <h1 className="numeral-font numeral_size text-md-left text-center  font-weight-light title_size yellow_text numeral2_marg">
                  10
                </h1>
                <h2 className="text-md-left text-center  secondary-font text-light font-weight-light numeral2_marg">
                  donadores conscientes
                </h2>
              </div>

              <div className="numeral_up">
                <h1 className="numeral-font numeral_size text-md-left text-center  font-weight-light title_size yellow_text ">
                  12
                </h1>
                <h2 className="text-md-left text-center  secondary-font text-left text-light font-weight-light">
                  talleres organizados
                </h2>
              </div>
            </Col>
            <Col xs={12} lg={4}></Col>
          </Row>

          <Row className="d-lg-none my-auto">
            <Col className="p-0">
              <CarouselImpact className="" />
            </Col>
          </Row>
        </Container>
      </section>
  );
}
export default ImpactDown;
